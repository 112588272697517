<template>
    <form action="" class="contact-form" id="contact-form">
        <div class="form-row">
            <div class="form-group">
                <label class="form-label" for="lastname">{{ fields.lastname }}<span class="required">*</span></label>
                <input class="form-control" v-model="form.lastname" type="text" name="lastname" id="lastname" required>
                <small v-if="errors && errors.lastname">{{ errors.lastname }}</small>
            </div>
            <div class="form-group">
                <label class="form-label" for="firstname">{{ fields.firstname }}<span class="required">*</span></label>
                <input class="form-control" v-model="form.firstname" type="text" name="firstname" id="firstname" required>
                <small v-if="errors && errors.firstname">{{ errors.firstname }}</small>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group">
                <label class="form-label" for="company">{{ fields.company }}<span class="required">*</span></label>
                <input class="form-control" v-model="form.company" type="text" name="company" id="company" required>
                <small v-if="errors && errors.company">{{ errors.company }}</small>
            </div>
            <div class="form-group">
                <label class="form-label" for="activity">{{ fields.activity }}<span class="required">*</span></label>
                <input class="form-control" v-model="form.activity" type="text" name="activity" id="activity" required>
                <small v-if="errors && errors.activity">{{ errors.activity }}</small>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-12">
                <label class="form-label" for="address">{{ fields.street }}</label>
                <input class="form-control" v-model="form.address" type="text" name="address" id="address">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-8">
                <label class="form-label" for="city">{{ fields.city }}</label>
                <input class="form-control" v-model="form.city" type="text" name="city" id="city">
            </div>
            <div class="form-group col-md-3">
                <label class="form-label" for="zipcode">{{ fields.zipcode }}</label>
                <input class="form-control" v-model="form.zipcode" type="text" name="zipcode" id="zipcode">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-12">
                <label class="form-label" for="country">{{ fields.country }}</label>
                <input class="form-control" v-model="form.country" type="text" name="country" id="country">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group">
                <label class="form-label" for="phone">{{ fields.phone }}</label>
                <input class="form-control" v-model="form.phone" type="tel" name="phone" id="phone">
                <small v-if="errors && errors.phone">{{ errors.phone }}</small>
            </div>
            <div class="form-group">
                <label class="form-label" for="email">{{ fields.email }}<span class="required">*</span></label>
                <input class="form-control" v-model="form.email" type="email" name="email" id="email" required>
                <small v-if="errors && errors.email">{{ errors.email }}</small>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-12">
                <label class="form-label" for="message">{{ fields.message }}<span class="required"></span></label>
                <textarea class="form-control" v-model="form.message" name="message" id="message" cols="30" rows="5"></textarea>
                <small v-if="errors && errors.message">{{ errors.message }}</small>
            </div>
        </div>
        <div class="form-row -acceptance">
            <div class="form-group col-12">
                <input type="checkbox" name="rgpd" id="rgpd">
                <label class="form-label -acceptance" for="rgpd">{{ form.rgpd }}</label>
                <small v-if="errors && errors.rgpd">{{ errors.rgpd }}</small>
            </div>
        </div>
        <div class="form-row row-submit">
            <div class="form-group">
                <button class="btn btn-primary" type="submit" @click.prevent="handleSubmitForm">Envoyer</button>
            </div>
        </div>
        <div v-if="message" id="alerts" class="alerts-container form-row">
            <div class="alert col-12"  v-bind:class="[ isSuccess ? 'alert-success': 'alert-danger' ]">
                {{message}}
            </div>
        </div>
    </form>
</template>

<script setup>
import Axios from "axios";
import {ref} from "vue";

const form = ref({
    lastname: '',
    firstname: '',
    company: '',
    activity: '',
    address: '',
    city: '',
    zipcode: '',
    country: '',
    phone: '',
    email: '',
    message: '',
    rgpd: '',
})

const errors = ref();
const isSending = ref(false);
const message = ref();
const isSuccess = ref();

const fields = ref({
    lastname: 'Nom',
    firstname: 'Prénom',
    company: 'Société',
    activity: 'Activité',
    street: 'Adresse',
    city: 'Ville',
    zipcode: 'Code postal',
    country: 'Pays',
    phone: 'Téléphone',
    email: 'Email',
    message: 'Votre demande'
});

// eslint-disable-next-line
if(frontend_ajax_object.rgpd) {
    // eslint-disable-next-line
    form.value.rgpd = frontend_ajax_object.rgpd;
} else {
    form.value.rgpd = "J'accepte les conditions d'utilsation";
}
/* eslint-disable */
if (frontend_ajax_object.fields) {
        fields.value.lastname = frontend_ajax_object.fields.lastname;
        fields.value.firstname = frontend_ajax_object.fields.firstname;
        fields.value.company = frontend_ajax_object.fields.company;
        fields.value.activity = frontend_ajax_object.fields.activity;
        fields.value.street = frontend_ajax_object.fields.street;
        fields.value.city = frontend_ajax_object.fields.city;
        fields.value.zipcode = frontend_ajax_object.fields.zipcode;
        fields.value.country = frontend_ajax_object.fields.country;
        fields.value.phone = frontend_ajax_object.fields.phone;
        fields.value.email = frontend_ajax_object.fields.email;
        fields.value.message = frontend_ajax_object.fields.message;
}

const handleSubmitForm = () => {
    isSending.value = true;

    // eslint-disable-next-line
    const url = frontend_ajax_object.ajaxurl;

    // eslint-disable-next-line
    Axios.post(url, {
            action: 'contact_form',
            ...form.value,
            // eslint-disable-next-line
            nonce: frontend_ajax_object.nonce
        }, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
    .then((response) => {
        //console.log(response);
        errors.value = '';
        form.value= {
            lastname: '',
            firstname: '',
            company: '',
            activity: '',
            address: '',
            city: '',
            zipcode: '',
            country: '',
            phone: '',
            email: '',
            message: '',
            rgpd: '',
        };
        message.value = response.data.message;
        isSuccess.value = response.data.email_success;

        // Scroll to the alert element
        setTimeout(() => {
            const alertElement = document.getElementById('alerts');
            if (alertElement) {
                alertElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    })
    .catch((error) => {
        console.error(error.response)
        errors.value = error.response.data.errors;
        message.value = error.response.data.message
        isSuccess.value = error.response.data.success;

        // Scroll to the alert element
        setTimeout(() => {
            const alertElement = document.getElementById('alerts');
            if (alertElement) {
                alertElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    })
    .finally( () => {
        isSending.value = false
    })
}
</script>